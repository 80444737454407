<template>
  <div class="paymentEvidenceForm__preview mb-4">
    <p v-if="appointmentInfo.second_opinion_paid">
      Segunda opinion aprobada por:
      {{ appointmentInfo.paid_user_complete_name }}
      <font-awesome-icon class="text-success" :icon="['fas', 'check']" />
    </p>
    <div class="row" v-if="hasBothFileTypes">
      <ul class="nav nav-tabs">
        <li class="nav-item" v-for="(tab, index) in tabMenu" :key="index">
          <a
            class="nav-link"
            @click="activeView = index"
            :class="{
              'active text-primary fw-bolder': index === activeView,
              'disabled text-muted fw-bolder': isTabDisabled(tab),
            }"
            >{{ tab }}</a
          >
        </li>
      </ul>
    </div>
    <div class="text-end" v-if="activeView === 0 && canPrintImage">
      <button class="btn btn-secondary" @click="generatePDFImage">
        Imprimir Imágenes
        <font-awesome-icon size="2xl" :icon="['fa', 'file-pdf']" />
      </button>
    </div>
    <ImagesToPdf
      v-if="openImagePDF && activeView === 0"
      :filename="`Comprobante_${appointmentInfo.patient_name}_${appointmentInfo.date}`"
      ref="previewImage"
      :key="imagePDFKey"
      @updateKey="resetComponent"
      :images="filterFilesOnlyImages" />
    <CarouselContent
      :fileType="activeView"
      :filesToShow="arrayFilesToShow"
      :index="index"
      @next="index += 1"
      @back="index -= 1" />
  </div>
</template>

<script>
import paymentModal from "@/mixin/paymentModal.mixin";
import { mapGetters } from "vuex";

export default {
  name: "PreviewContent",
  components: {
    CarouselContent: () => import("@/components/shared/CarouselContent.vue"),
    ImagesToPdf: () => import("@/components/shared/ImagesToPdf.vue"),
  },
  mixins: [paymentModal],
  data() {
    return {
      imagePDFKey: 0,
      index: 0,
      tabMenu: ["Imágenes", "Archivos PDF"],
      openImagePDF: true,
    };
  },
  computed: {
    ...mapGetters(["getUserType"]),
    activeView() {
      if (this.filterFilesOnlyImages.length === 0) {
        return 1;
      }
      return 0;
    },
    canPrintImage() {
      const canPrintImage = ["superadmin", "cash_register"];
      if (canPrintImage.includes(this.getUserType)) return true;
      return false;
    },
    hasBothFileTypes() {
      return (
        this.filterFilesOnlyImages.length > 0 &&
        this.filterFilesOnlyPdfs.length > 0
      );
    },
    filterFilesOnlyPdfs() {
      return this.filesToShow.filter((file) => file.file_type === "file");
    },
    filterFilesOnlyImages() {
      return this.filesToShow.filter((file) => file.file_type === "image");
    },
    arrayFilesToShow() {
      if (this.activeView === 0) {
        return this.filterFilesOnlyImages;
      }
      return this.filterFilesOnlyPdfs;
    },
  },
  methods: {
    resetComponent() {
      this.imagePDFKey += 1;
      this.openImagePDF = false;
      this.$nextTick(() => {
        this.openImagePDF = true;
        this.imagePDFKey += 1;
      });
    },
    generatePDFImage() {
      this.imagePDFKey += 1;
      this.$refs.previewImage.$refs.previewImage.generatePdf();
      this.$nextTick(() => {
        this.$store.getters.getModal("paymentAppointmentModal").hide();
      });
    },
    isTabDisabled(tab) {
      if (tab === "Imágenes") {
        return this.filterFilesOnlyImages.length === 0;
      }
      return this.filterFilesOnlyPdfs.length === 0;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(1);
          }
        });
    },
  },
};
</script>

<style></style>
